import { mapActions, mapMutations, mapState } from 'vuex'
import router from '../../../../plugins/router'
import { recaptcha } from '../../../../mixins/recaptcha'
import backIcon from '../../../../assets/images/issues/back_eco_icon.svg'
import SLink from '../../../../components/ui/s-link'
import IssueForm from '../../../../components/IssueForm'

export default {
  name: 'OpenChannelIssuesForm',
  mixins: [recaptcha],
  components: {
    SLink,
    IssueForm,
  },

  created() {
    const { agree } = router.currentRoute.params

    if (!agree) {
      router.push({ name: 'issues', slug: this.slug })
    }
  },

  data() {
    return {
      protocol: null,
      slug: router.currentRoute.params?.slug,
      backIcon,
    }
  },

  computed: mapState(['issueId']),

  methods: {
    ...mapMutations(['setError', 'setNotice']),
    ...mapActions(['createIssue']),

    async submitIssue(params) {
      this.protocol = params.protocol

      const success = await this.performRecaptcha('issues').catch(this.setError)

      if (success) {
        const issue = await this.createIssue(params)

        router.push({
          name: 'issue-status',
          params: {
            slug: this.slug,
            code: this.protocol,
            issueId: issue.id,
          },
        })
      } else {
        this.showNotice = false
        this.setError('UNAVAILABLE')
      }
    },
  },
}
