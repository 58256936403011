import { mapMutations, mapState } from 'vuex'
import { Protocolizer, isNullOrEmpty } from '../../plugins/helper'

import SLink from '../ui/s-link'
import SButton from '../ui/s-button'
import FileUpload from '../FileUpload'

import Metrics from '../../plugins/metrics'
import backIcon from '../../assets/images/issues/back_eco_icon.svg'

export default {
  name: 'IssuesForm',

  props: ['channel'],

  components: {
    FileUpload,
    SLink,
    SButton,
  },

  data() {
    return {
      backIcon,
      copied: false,
      copyKey: 'copy',
      issue: {
        anonymous: null,
        protocol: Protocolizer(),
        message: '',
        name: '',
        files: [],
        misconducts: [],
      },
      showError: false,
      showNotice: false,
      misconducts: [],
      maxLength: 25,
    }
  },

  methods: {
    ...mapMutations(['setError', 'setNotice']),

    async submitForm() {
      this.showNotice = false
      this.showError = false

      if (!this.isValid) {
        this.setError(this.$t('invalid'))
        return
      }

      if (this.issue.anonymous) {
        this.issue.name = ''
      }

      this.$emit('createIssue', this.issue)
    },

    copy() {
      this.setNotice('REPORT_ANONYMOUS_COPY_OK')
      this.showNotice = true
      this.copyKey = 'copied'
      const protocol = this.$refs.protocol
      protocol.setAttribute('type', 'text')
      protocol.select()

      try {
        document.execCommand('copy')
      } catch (err) {
        this.setError('Oops, unable to copy')
      }

      protocol.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },

    attach(key) {
      this.issue.files.push(key)
    },

    detach(key) {
      const item = this.issue.files.indexOf(key)
      if (item > -1) this.issue.files.splice(item, 1)
    },
  },

  computed: {
    ...mapState([
      'notice',
      'error',
      'companyId',
      'currentUser',
      'loading',
      'isAnonymousUser',
    ]),

    messageIsValid() {
      return !isNullOrEmpty(this.issue.message?.trim())
    },

    isValid() {
      const { anonymous } = this.issue
      return (
        (this.messageIsValid && anonymous === false) || // !null
        (this.messageIsValid && anonymous && this.copied)
      )
    },
  },

  watch: {
    error() {
      if (this.error) this.showError = true
    },

    notice() {
      this.showNotice = this.notice == 'ISSUE_SENT'
    },

    profile() {
      const { name } = this.currentUser || {}
      this.issue.name = this.isAnonymousUser ? '' : name
    },
  },

  async mounted() {
    const { name = '' } = this.currentUser
    this.issue.name = this.isAnonymousUser ? '' : name

    Metrics.track('Issue Started', {
      code: this.issue?.protocol,
      channel: this.channel,
    })
  },
}
